import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import SettingsTab from "../components/SettingsTab";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Popover, Radio, RadioGroup, Stack, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiAccountOutline, mdiBarcodeScan, mdiCalendarWeekBeginOutline, mdiClose, mdiDotsVertical, mdiExportVariant, mdiHistory, mdiMagnify, mdiPencilOutline, mdiPlus, mdiSort, mdiTrashCanOutline } from "@mdi/js";
import DeleteConfirmDialog from "../../../components/common/DeleteConfirmDialog";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import CreateNewProductDialog from "../components/CreateNewProductDialog";
import useAxios from "../../../hooks/useAxios";
import AvatarInitials from "../../../components/common/AvatarInitials";
import { useLoader } from "../../../components/context/LoaderContext";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import ExportData from "../../../components/ExportData";
import { capitalize, DeleteDialog, DeleteDialogConfig, displayCurrencySymbol } from "../../../utils/Utils";
import EmptyState from "../../../components/common/EmptyState";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

function Products(props) {
    const navigate = useNavigate();
    const [sortByAnchorEl, setSortByAnchorEl] = React.useState(null);
    const axiosInstance = useAxios();
    const [searchParams] = useSearchParams();
    const { showLoader, hideLoader, loading } = useLoader();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );
    const { preferredDateFormat, preTimeZoneKey, currencySymbol, currencyCode, currencyStringValue } = useSelector((state) => state?.user);
    const userPermissions = useSelector((state) => state.user.permission);
    const sortByParam = searchParams.get("sort_field") || "created_at";
    const orderByParam = searchParams.get("sort_order") || process.env.REACT_APP_DEFAULT_SORT;
    const [handleSortBy, setHandleSortBy] = useState(sortByParam);
    const [handleOrderBy, setHandleOrderBy] = useState(orderByParam);
    const { showSnackbar } = useSnackbar();
    const [checkedItems, setCheckedItems] = useState({});
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [sortBy, setSortBy] = useState("created_at");
    const [search, setSearch] = useState('');
    const [searchUser, setSearchUser] = useState('All Users');
    const [clearType, setClearType] = useState(false);
    const [reloadPage, setReloadPage] = useState(false);
    const [products, setProducts] = useState();
    const [userLists, setUserLists] = useState([]);

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchUser
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        searchUser,
        reloadPage,
        clearType,
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
        searchUser
    ) => {
        const params = {
            search: search,
            search_user_id: searchUser === "All Users" ? "" : searchUser,
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
        };

        await axiosInstance
            .get(`api/product?page=${currentPage + 1}`, { params }).then((response) => {
                if (response.status === 200) {
                    setProducts(response?.data?.data?.records?.data);
                    setTotalCount(response?.data?.data?.records?.total);
                    setUserLists(response?.data?.data?.users || []);
                    hideLoader();
                    setCheckedItems({});
                    setIsCheckAll(false);
                }
            })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    async function handleBulkDelete() {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            bulkDeleteData(checkedIds)
        } else {
            showSnackbar('Please select at least one record to Delete.', 'warning')
        }
    }

    const bulkDeleteData = async (ids) => {
        const data = {
            id: ids,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        try {
                            showLoader()
                            const response = await axiosInstance.delete('api/product/bulk-destroy', { data });
                            if (response.status === 200) {
                                showSnackbar(response.data.message, "success");
                                setCheckedItems({});
                                setIsCheckAll(false);
                                hideLoader();
                                setReloadPage(reloadPage ? false : true);
                            }
                        } catch (error) {
                            if (error.response) {
                                hideLoader();
                                if (error.response.status === 400) {
                                    showSnackbar(error.response.data.message, "warning");
                                }
                            }
                        }
                    },
                    cancel: () => {
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }


    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/product/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                        navigate("/settings/products");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    const HandleSearchUser = (event) => {
        setSearchUser(event.target.value);
    };

    const handleCheckAll = (event) => {
        const { checked } = event.target;
        setIsCheckAll(checked);
        const newCheckedItems = {};
        products.forEach((lead) => {
            newCheckedItems[lead.id] = checked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({
            ...checkedItems,
            [name]: checked,
        });

        if (!checked) {
            setIsCheckAll(false);
        } else {
            const allChecked = products.every(
                (lead) => checkedItems[lead.id] || lead.name === name
            );
            setIsCheckAll(allChecked);
        }
    };

    const handleSortChange = (event) => {
        const { name, value } = event.target;
        if (name === "sort-by-radio") {
            setHandleSortBy(value);
        } else if (name === "sort-order-radio") {
            setHandleOrderBy(value);
        }
    }

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        } else if (type === 'user') {
            setSearchUser('All Users')
        } else {
            setSearchUser("All Users");
            setSearch('')
        }

    }

    const handleReloadFunc = () => {
        setCheckedItems({});
        setIsCheckAll(false);
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }

    const getCheckedIds = () => {
        return Object.keys(checkedItems).filter((id) => checkedItems[id]);
    };
    const checkedIds = getCheckedIds();
    const expParams = {
        search: search,
        search_user_id: searchUser === "All Users" ? "" : searchUser,
        sort_field: sortBy,
        sort_order: sortOrder,
        per_page: perPage,
        selectedIds: checkedIds && checkedIds.length > 0 ? checkedIds : []
    };

    const handleSortByReset = () => {
        setSortByAnchorEl(null);
        setSortBy("created_at");
        setSortOrder("desc");
    };

    const handleSortByApply = () => {
        //click to manage apply button....
        setSortBy(handleSortBy);
        setSortOrder(handleOrderBy);
        setSortByAnchorEl(null);
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleSortByClick = (event) => {
        setSortByAnchorEl(event.currentTarget);
    };
    const handleSortByClose = () => {
        setSortByAnchorEl(null);
    };
    const sortByOpen = Boolean(sortByAnchorEl);



    const [productPageContextMenuAnchorEl, setProductPageContextMenuAnchorEl] = React.useState(null);
    const productPageContextMenuOpen = Boolean(productPageContextMenuAnchorEl);
    const handleProductPageContextMenuClick = (event) => {
        setProductPageContextMenuAnchorEl(event.currentTarget);
    };
    const handleProductPageContextMenuClose = () => {
        setProductPageContextMenuAnchorEl(null);
    };


    const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = React.useState(false);
    const openDeleteConfirmDialog = () => {
        setDeleteConfirmDialogOpen(true);
    }
    const closeDeleteConfirmDialog = () => {
        setDeleteConfirmDialogOpen(false);
    }


    const [createNewProductDialogOpen, setCreateNewProductDialogOpen] = React.useState(false);
    const openCreateNewProductDialog = () => {
        setCreateNewProductDialogOpen(true);
    }
    const closeCreateNewProductDialog = () => {
        setCreateNewProductDialogOpen(false);
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default form submission behavior
            getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
            );
        }
    };

    const addDefaultProfileSrc = (e) => {
        e.target.src = `${process.env.REACT_APP_API_URL}assets/default-profile-image.png`;
    }

    return (
        <>
            <Helmet>
                <title>Products | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <SettingsTab />

            <Grid container className='page-action-bar'>
                <Grid item xs={'auto'}>
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                        <Checkbox
                            className="grid-checkbox"
                            checked={isCheckAll}
                            onChange={handleCheckAll}
                        />
                        <TextField
                            variant="filled"
                            placeholder="Search Products"
                            name="searchData"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="size-small page-search no-margin"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon path={mdiMagnify} color="currentColor" size={1} />
                                        {
                                            search &&
                                            <IconButton onClick={() => clearUserFilter('search')} >
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            select
                            value={searchUser}
                            variant="filled"
                            className="size-small no-label no-margin"
                            onChange={HandleSearchUser}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end' className='clear-filter'>
                                        {
                                            searchUser && searchUser !== 'All Users' &&
                                            <IconButton onClick={() => clearUserFilter('user')} >
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        >
                            <MenuItem key={12224} value="All Users">
                                All Users
                            </MenuItem>
                            {userLists &&
                                userLists.length > 0 &&
                                userLists.map((user, index) => (
                                    <MenuItem key={index} value={user.id}>
                                        {user.name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Stack>
                </Grid>

                <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                    <Stack direction="row" spacing={1}>
                        {userPermissions && userPermissions.includes("product_create") &&
                            <Button size="small" onClick={openCreateNewProductDialog}>
                                <span>
                                    <Icon path={mdiPlus} color="currentColor" size={1} />
                                    <span>Create New</span>
                                </span>
                            </Button>
                        }
                        <Button variant="contained" color="secondary" size='small' onClick={handleSortByClick}>
                            <span>
                                <Icon path={mdiSort} color="currentColor" size={1} />
                                <span>Sort by</span>
                            </span>
                        </Button>
                        <Popover
                            open={sortByOpen}
                            anchorEl={sortByAnchorEl}
                            onClose={handleSortByClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            classes={{
                                paper: 'utility-menu'
                            }}
                        >
                            <Typography variant='label-small-semibold' className='small-title'>Sort by</Typography>
                            <RadioGroup
                                //defaultValue="name"
                                value={handleSortBy}
                                name="sort-by-radio"
                                onChange={handleSortChange}
                            >
                                <FormControlLabel
                                    value="name"
                                    control={<Radio />}
                                    label="Name"
                                />
                                <FormControlLabel
                                    value="code"
                                    control={<Radio />}
                                    label="Code"
                                />
                                <FormControlLabel
                                    value="price"
                                    control={<Radio />}
                                    label="Price"
                                />
                                <FormControlLabel
                                    value="tax"
                                    control={<Radio />}
                                    label="Tax"
                                />
                            </RadioGroup>

                            <Typography variant='label-small-semibold' className='small-title'>Sort Order</Typography>
                            <RadioGroup
                                //defaultValue="desc"
                                value={handleOrderBy}
                                name="sort-order-radio"
                                onChange={handleSortChange}
                            >
                                <FormControlLabel
                                    value="asc"
                                    control={<Radio />}
                                    label="Ascending"
                                />
                                <FormControlLabel
                                    value="desc"
                                    control={<Radio />}
                                    label="Descending"
                                />
                            </RadioGroup>

                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Button
                                        size="small"
                                        className="btn-block"
                                        onClick={handleSortByApply}
                                    >
                                        Apply
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        className="btn-block"
                                        onClick={handleSortByReset}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </Popover>

                        <Tooltip title="More">
                            <span>
                                <Button variant="contained" color="secondary" size='small' className='icon-only' onClick={handleProductPageContextMenuClick}>
                                    <Icon path={mdiDotsVertical} color="currentColor" size={1} />
                                </Button>
                            </span>
                        </Tooltip>
                        <Menu
                            anchorEl={productPageContextMenuAnchorEl}
                            open={productPageContextMenuOpen}
                            onClose={handleProductPageContextMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={handleProductPageContextMenuClose}>
                                <Icon path={mdiExportVariant} color={'currentColor'} size={1} />
                                <ExportData params={expParams} fileName="Product" api="product" handleReloadFunc={handleReloadFunc} />
                            </MenuItem>
                            {userPermissions && userPermissions.includes("product_delete") &&
                                <MenuItem onClick={e => {
                                    // handleProductPageContextMenuClose();
                                    // openDeleteConfirmDialog();
                                    handleBulkDelete(); handleProductPageContextMenuClose();
                                }}>
                                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                    <span>Delete</span>
                                </MenuItem>
                            }
                        </Menu>
                    </Stack>
                </Grid>
            </Grid>
            {products && products.length > 0 && (
                <ul className="primary-list">
                    {products.map((product, index) =>
                        <li key={index + ''}>
                            <Link to={userPermissions?.includes("product_edit") ? '/settings/product-detail/' + product?.id : '#'} className="card">
                                <Grid container alignItems={'flex-start'}>
                                    <Grid item xs={'auto'}>
                                        <Checkbox className='grid-checkbox' onClick={e => { e.stopPropagation(); }}
                                            name={String(product.id)}
                                            checked={checkedItems[product.id] || false}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Grid container alignItems={'flex-start'} spacing={1.5} className="lead-details">
                                            <Grid item xs="auto">
                                                {product?.images?.length > 0 ? (
                                                    <img
                                                        onError={addDefaultProfileSrc}
                                                        src={product?.images[0]?.file_url}
                                                        alt="Profile"
                                                        className="user-avatar"
                                                    />
                                                ) : (
                                                    <AvatarInitials name={product?.name} />
                                                )}
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='title-medium-bold' component={'h6'}>{capitalize(product?.name || 'N/A')}</Typography>
                                                <ul className='inline-details'>
                                                    <li><Tooltip title="Price"><span className="bold">{product?.price ? displayCurrencySymbol(product?.price, currencyCode, currencySymbol, currencyStringValue) : 'N/A'}</span></Tooltip></li>
                                                    <li>
                                                        <Tooltip title="Product Code">
                                                            <span>
                                                                <Icon path={mdiBarcodeScan} color="currentColor" size={1} />{product?.product_code || 'N/A'}
                                                            </span>
                                                        </Tooltip>
                                                    </li>
                                                    <li><span className="title">Tax:</span>{product?.tax ? displayCurrencySymbol(product?.tax, currencyCode, currencySymbol, currencyStringValue) : 'N/A'}</li>
                                                    <li>
                                                        <Tooltip title="Created Date">
                                                            <span>
                                                                <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />
                                                                {product?.created_at
                                                                    ? moment
                                                                        .utc(product.created_at)
                                                                        .tz(preTimeZoneKey)
                                                                        .format(preferredDateFormat)
                                                                    : "N/A"}
                                                            </span>
                                                        </Tooltip>
                                                    </li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <ul className='inline-details other-info'>
                                    <li>
                                        <Tooltip title="Last Updated">
                                            <span>
                                                <Icon path={mdiHistory} color="currentColor" size={1} />
                                                {product?.updated_at
                                                    ? moment
                                                        .utc(product.updated_at)
                                                        .tz(preTimeZoneKey)
                                                        .format(preferredDateFormat)
                                                    : "N/A"}
                                            </span>
                                        </Tooltip>
                                    </li>
                                    <li>
                                        <Tooltip title="Added by">
                                            <span>
                                                <Icon path={mdiAccountOutline} color="currentColor" size={1} />{product?.added_by?.name || 'N/A'}
                                            </span>
                                        </Tooltip>
                                    </li>
                                </ul>
                            </Link>
                            <Stack direction={'row'} className='actions'>
                                {userPermissions && userPermissions.includes("product_edit") &&
                                    <Tooltip title="Edit">
                                        <span>
                                            <Link to={'/settings/product-detail/' + product?.id} className='icon-button'>
                                                <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                            </Link>
                                        </span>
                                    </Tooltip>
                                }
                                {userPermissions && userPermissions.includes("product_delete") &&
                                    <Tooltip title="Delete">
                                        <span>
                                            <button type="button" className='icon-button' onClick={() => { deleteData(product.id); }}>
                                                <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                            </button>
                                        </span>
                                    </Tooltip>
                                }
                            </Stack>
                        </li>
                    )}
                </ul>
            )}

            {(!products || products.length === 0) && !loading &&
                <EmptyState type={'product'} hideActionBtn />
            }

            {
                products && products.length > 0 &&
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            }


            <CreateNewProductDialog open={createNewProductDialogOpen} onClose={closeCreateNewProductDialog} handleReloadFunc={handleReloadFunc} />
            <DeleteConfirmDialog open={deleteConfirmDialogOpen} onClose={closeDeleteConfirmDialog} message='Are you sure you want to delete this product? This action cannot be undone.' deleteButtonText='Delete Product' />
        </>
    );
}

export default Products;