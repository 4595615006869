import React, { useState, useEffect, Suspense } from "react";
import { Helmet } from "react-helmet";
import SettingsTab from "../components/SettingsTab";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Popover, Radio, RadioGroup, Stack, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import { mdiCalendarWeekBeginOutline, mdiClose, mdiDotsVertical, mdiEmailOutline, mdiExportVariant, mdiHistory, mdiMagnify, mdiPencilOutline, mdiPhoneOutline, mdiPlus, mdiSendOutline, mdiSort, mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import CreateNewUserDialog from "../../settings/components/CreateNewUserDialog";
import { Link, useSearchParams } from "react-router-dom";
import AvatarInitials from "../../../components/common/AvatarInitials";
import { useLoader } from "../../../components/context/LoaderContext";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import EmptyState from "../../../components/common/EmptyState";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { capitalize, DeleteDialog, DeleteDialogConfig } from "../../../utils/Utils";
import ExportData from "../../../components/ExportData";

function Users(props) {
    const axiosInstance = useAxios();
    const { showLoader, hideLoader, loading } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [users, setUsers] = useState();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [clearType, setClearType] = useState(false);
    const [roles, setRoles] = useState([]);
    const [memberShipDetail, setMemberShipDetail] = useState(false);
    const userPermissions = useSelector((state) => state.user.permission);
    // Extract URL parameters
    const [searchParams] = useSearchParams();
    const orderByParam = searchParams.get("sort_order") || process.env.REACT_APP_DEFAULT_SORT;
    const sortByParam = searchParams.get("sort_field") || "created_at";

    //add for handle apply button
    const [handleSortBy, setHandleSortBy] = useState(sortByParam);
    const [handleOrderBy, setHandleOrderBy] = useState(orderByParam);

    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );

    const [sortByAnchorEl, setSortByAnchorEl] = useState(false);

    const [sortBy, setSortBy] = useState("created_at");
    const [sortByOpen, setSortByOpen] = useState(sortByAnchorEl);


    // State management for search fields
    const [search, setSearch] = useState("");
    const [searchEmailVerified, setSearchEmailVerified] = useState('All Emails');

    //handle checkbox
    const [checkedItems, setCheckedItems] = useState({});
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [reloadPage, setReloadPage] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchEmailVerified,
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        reloadPage,
        searchEmailVerified,
        clearType,
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
    ) => {
        const params = {
            search: search,
            search_email_verified_at: searchEmailVerified === "All Emails" ? "" : searchEmailVerified,
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
        };
        showLoader()
        await axiosInstance.get(`api/user?page=${currentPage + 1}`, { params }).then((response) => {
            if (response.status === 200) {
                setUsers(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                setRoles(response?.data?.data?.roles)
                hideLoader()
                calculateSubUserData(
                    response?.data?.data?.memberShipDetail?.membershipType,
                    response?.data?.data?.memberShipDetail?.addedUserCount,
                    response?.data?.data?.memberShipDetail?.planUserCount
                );
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };


    //=====Start::Write a code for handle headeMenu option ======//
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleSortByClickToOpen = (event) => {
        setSortByAnchorEl(event.currentTarget);
        setSortByOpen(true);
    };

    const handleSortByClose = () => {
        setSortByOpen(false);
        setSortByAnchorEl(null);
    };

    const handleSortByReset = () => {
        setSortByOpen(false);
        setSortByAnchorEl(null);

        setSortBy("created_at");
        setSortOrder("desc");
    };

    const handleSortByApply = () => {
        //click to manage apply button....
        setSortBy(handleSortBy);
        setSortOrder(handleOrderBy);

        setSortByOpen(false);
        setSortByAnchorEl(null);
    };

    const handleSortChange = (event) => {
        const { name, value } = event.target;
        if (name === "sort-by-radio") {
            //setSortBy(value); //if dont want to click on apply button than open it and hide setHandleSortBy
            setHandleSortBy(value);
        } else if (name === "sort-order-radio") {
            //setSortOrder(value);
            setHandleOrderBy(value);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default form submission behavior
            getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchEmailVerified,
            );
        }
    };

    const handleCheckAll = (event) => {
        const { checked } = event.target;
        setIsCheckAll(checked);
        const newCheckedItems = {};
        users.forEach((user) => {
            newCheckedItems[user.id] = checked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({
            ...checkedItems,
            [name]: checked,
        });

        if (!checked) {
            setIsCheckAll(false);
        } else {
            const allChecked = users.every(
                (user) => checkedItems[user.id] || user.name === name
            );
            setIsCheckAll(allChecked);
        }
    };


    function calculateSubUserData(type, addedUser, limitUser) {
        if (type === "free") {
            setMemberShipDetail(true);
        } else {
            if (addedUser < limitUser) {
                setMemberShipDetail(true);
            } else {
                setMemberShipDetail(false);
            }
        }
    }

    // Get list of checked item IDs
    const getCheckedIds = () => {
        return Object.keys(checkedItems).filter((id) => checkedItems[id]);
    };

    // Handle single delete record
    async function deleteData(id, type) {
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete("api/user/destroy/" + id + "?type=" + type)
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                    calculateSubUserData(
                                        response?.data?.data?.memberShipDetail?.membershipType,
                                        response?.data?.data?.memberShipDetail?.addedUserCount,
                                        response?.data?.data?.memberShipDetail?.planUserCount
                                    );
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    // Handle multiple delete records
    async function handleBulkDelete() {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            bulkDeleteData(checkedIds)
        } else {
            showSnackbar('Please select at least one record to Delete.', 'warning')
        }
    }

    // Handle multiple delete records
    const bulkDeleteData = async (ids) => {
        const data = {
            id: ids,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete('api/user/bulk-destroy', { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    setReloadPage(reloadPage ? false : true);
                                    hideLoader();
                                    calculateSubUserData(
                                        response?.data?.data?.memberShipDetail?.membershipType,
                                        response?.data?.data?.memberShipDetail?.addedUserCount,
                                        response?.data?.data?.memberShipDetail?.planUserCount
                                    );
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=====End::Write a code for handle headeMenu option ======//

    const handleReloadFunc = () => {
        setCheckedItems({});
        setIsCheckAll(false);
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }

    const [createNewUserDialogOpen, setCreateNewUserDialogOpen] = React.useState(false);
    const openCreateNewUserDialog = () => {
        if (memberShipDetail) {
            setCreateNewUserDialogOpen(true);
        } else {
            showSnackbar('You have reached your user limit. Upgrade your plan to add more users.', "error");
        }
    }
    const closeCreateNewUserDialog = () => {
        setCreateNewUserDialogOpen(false);
    }


    const [userPageContextMenuAnchorEl, setUserPageContextMenuAnchorEl] = React.useState(null);
    const userPageContextMenuOpen = Boolean(userPageContextMenuAnchorEl);
    const handleDealPageContextMenuClick = (event) => {
        setUserPageContextMenuAnchorEl(event.currentTarget);
    };
    const handleUserPageContextMenuClose = () => {
        setUserPageContextMenuAnchorEl(null);
    };
    //=======End:Wtite a logic for change owner===========//

    //======Start:Write a Export data=====//
    const checkedIds = getCheckedIds();
    const expParams = {
        search: search,
        search_email_verified_at: searchEmailVerified === "All Emails" ? "" : searchEmailVerified,
        sort_field: sortBy,
        sort_order: sortOrder,
        per_page: perPage,
        selectedIds: checkedIds && checkedIds.length > 0 ? checkedIds : []
    };

    //======End:Write a Export data=====//

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        } else if (type === 'email') {
            setSearchEmailVerified('All Emails')
        } else {
            setSearch('')
            setSearchEmailVerified('All Emails')
        }
    }

    const reSendInvitation = async (id, email, roleId) => {
        const data = {
            email: email,
            role_id: roleId,
            type: "resend",
            id: id,
        };
        showLoader()
        await axiosInstance.post("/api/user/invitation/store", data).then((response) => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, "success");
                getData(currentPage);
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    const addDefaultProfileSrc = (e) => {
        e.target.src = `${process.env.REACT_APP_API_URL}assets/default-profile-image.png`;
    }

    return (
        <>
            <Helmet>
                <title>Users | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <SettingsTab />

            <Grid container className='page-action-bar'>
                <Grid item xxl={'auto'} xs={12} sx={{ marginLeft: { xxl: 'auto' }, order: { xxl: 1 }, marginBottom: { xxl: 0, xs: '12px' } }}>
                    <Stack direction="row" spacing={1}>
                        {userPermissions && userPermissions.includes("user_create") &&
                            <Button size="small" onClick={openCreateNewUserDialog}>
                                <span>
                                    <Icon path={mdiPlus} color="currentColor" size={1} />
                                    <span>Create New</span>
                                </span>
                            </Button>
                        }
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={handleSortByClickToOpen}
                        >
                            <span>
                                <Icon path={mdiSort} color="currentColor" size={1} />
                                <span>Sort by</span>
                            </span>
                        </Button>
                        <Popover
                            open={sortByOpen}
                            anchorEl={sortByAnchorEl}
                            onClose={handleSortByClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            classes={{
                                paper: "utility-menu",
                            }}
                        >
                            <Typography
                                variant="label-small-semibold"
                                className="small-title"
                            >
                                Sort by
                            </Typography>
                            <RadioGroup
                                //defaultValue="name"
                                value={handleSortBy}
                                name="sort-by-radio"
                                onChange={handleSortChange}
                            >
                                <FormControlLabel
                                    value="name"
                                    control={<Radio />}
                                    label="Name"
                                />
                                <FormControlLabel
                                    value="phone1"
                                    control={<Radio />}
                                    label="Phone"
                                />
                                <FormControlLabel
                                    value="email"
                                    control={<Radio />}
                                    label="Email"
                                />
                                <FormControlLabel
                                    value="created_at"
                                    control={<Radio />}
                                    label="Created at"
                                />
                            </RadioGroup>

                            <Typography
                                variant="label-small-semibold"
                                className="small-title"
                            >
                                Sort Order
                            </Typography>
                            <RadioGroup
                                //defaultValue="desc"
                                value={handleOrderBy}
                                name="sort-order-radio"
                                onChange={handleSortChange}
                            >
                                <FormControlLabel
                                    value="asc"
                                    control={<Radio />}
                                    label="Ascending"
                                />
                                <FormControlLabel
                                    value="desc"
                                    control={<Radio />}
                                    label="Descending"
                                />
                            </RadioGroup>

                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Button
                                        size="small"
                                        className="btn-block"
                                        onClick={handleSortByApply}
                                    >
                                        Apply
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        className="btn-block"
                                        onClick={handleSortByReset}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </Popover>
                        <Tooltip title="More">
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                className="icon-only"
                                onClick={handleDealPageContextMenuClick}
                            >
                                <Icon path={mdiDotsVertical} color="currentColor" size={1} />
                            </Button>
                        </Tooltip>

                        <Menu
                            anchorEl={userPageContextMenuAnchorEl}
                            open={userPageContextMenuOpen}
                            onClose={handleUserPageContextMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={(e) => { handleUserPageContextMenuClose() }}>
                                <Icon path={mdiExportVariant} color={"currentColor"} size={1} />
                                <ExportData params={expParams} fileName="User" api="user" handleReloadFunc={handleReloadFunc} />
                            </MenuItem>
                            {userPermissions && userPermissions.includes("user_delete") &&
                                <MenuItem
                                    onClick={() => {
                                        handleBulkDelete(); handleUserPageContextMenuClose();
                                    }}
                                >
                                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                    <span>Delete</span>
                                </MenuItem>
                            }
                        </Menu>
                    </Stack>
                </Grid>
                <Grid item xxl={"auto"} xs={12}>
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                        <Checkbox
                            className="grid-checkbox"
                            checked={isCheckAll}
                            onChange={handleCheckAll}
                        />
                        <TextField
                            variant="filled"
                            placeholder="Search users"
                            name="searchData"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="size-small page-search no-margin"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon path={mdiMagnify} color="currentColor" size={1} />
                                        {
                                            search &&
                                            <IconButton onClick={() => clearUserFilter('search')} >
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            select
                            //defaultValue="All Sources"
                            variant="filled"
                            value={searchEmailVerified}
                            className="size-small no-label no-margin"
                            onChange={(e) => setSearchEmailVerified(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end' className='clear-filter'>
                                        {
                                            searchEmailVerified && searchEmailVerified !== 'All Emails' &&
                                            <IconButton onClick={() => clearUserFilter('email')} >
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        >
                            <MenuItem key={1222} value="All Emails">
                                Email Verified All
                            </MenuItem>
                            <MenuItem key={1} value={1}>
                                Yes
                            </MenuItem>
                            <MenuItem key={1} value={0}>
                                No
                            </MenuItem>
                        </TextField>
                        {
                            (search || (searchEmailVerified !== 'All Emails')) &&
                            <a href="-" className="primary-link ml-12" onClick={e => { e.preventDefault(); clearUserFilter('all') }}><Typography variant='body-medium-medium'>Clear All</Typography></a>
                        }

                    </Stack>
                </Grid>
            </Grid>
            {users && users.length > 0 && (
                <ul className="primary-list">
                    {
                        users.map((user, index) => {
                            var displayName = user?.name || ''
                            var url = userPermissions?.includes("user_edit") && user?.selected_role !== 'SuperAdmin' ? '/settings/user-detail/' + user?.id : '#'
                            if (user.type !== 'user') {
                                var expName = user.email.split("@");
                                if (expName && expName.length === 2) {
                                    displayName = expName[0]
                                }
                                url = ''
                            }

                            var userImage = false
                            if (user?.profile_image) {
                                var expImage = user.profile_image.split('/assets/')

                                if (expImage.length && expImage[1] !== 'avatar-default.png') {
                                    userImage = true
                                }
                            }

                            return (
                                <li key={index + ''}>
                                    <Link to={url} className="card">
                                        <Grid container alignItems={'flex-start'}>
                                            <Grid item xs={'auto'}>
                                                <Checkbox
                                                    className="grid-checkbox"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                    name={String(user.id)}
                                                    checked={checkedItems[user.id] || false}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container alignItems={'flex-start'} spacing={1.5}>
                                                    <Grid item xs="auto">
                                                        {user?.profile_image && userImage ? (
                                                            <img
                                                                onError={addDefaultProfileSrc}
                                                                src={user?.profile_image}
                                                                alt="Profile"
                                                                className="user-avatar"
                                                            />
                                                        ) : (
                                                            <AvatarInitials name={displayName || ''} />
                                                        )}

                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant='title-medium-bold' component={'h6'}>{capitalize(displayName || 'N/A')}</Typography>
                                                        <ul className='inline-details'>
                                                            <li><Tooltip title="Role"><span>{user?.selected_role || 'N/A'}</span></Tooltip></li>
                                                            <li>
                                                                <Tooltip title="Email">
                                                                    <span>
                                                                        <Icon path={mdiEmailOutline} color="currentColor" size={1} />{user?.email || 'N/A'}
                                                                    </span>
                                                                </Tooltip>
                                                            </li>
                                                            <li>
                                                                <Tooltip title="Phone">
                                                                    <span>
                                                                        <Icon path={mdiPhoneOutline} color="currentColor" size={1} />{user?.phone1 || 'N/A'}
                                                                    </span>
                                                                </Tooltip>
                                                            </li>
                                                            {!user?.email_verified_at && <li>
                                                                <Tooltip title="Is user verified">
                                                                    <span>
                                                                        <span className="status-badge danger">Unverified</span>
                                                                    </span>
                                                                </Tooltip>
                                                            </li>
                                                            }
                                                        </ul>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <ul className='inline-details other-info'>
                                            <li>
                                                <Tooltip title="Created Date">
                                                    <span>
                                                        <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />
                                                        {user?.created_at
                                                            ? moment.utc(user.created_at)
                                                                .tz(preTimeZoneKey)
                                                                .format(preferredDateFormat)
                                                            : "N/A"}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                            <li>
                                                <Tooltip title="Last Updated">
                                                    <span>
                                                        <Icon path={mdiHistory} color="currentColor" size={1} /> {user?.updated_at
                                                            ? moment.utc(user.updated_at)
                                                                .tz(preTimeZoneKey)
                                                                .format(preferredDateFormat)
                                                            : "N/A"}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </Link>
                                    <Stack direction={'row'} className='actions'>
                                        {
                                            user?.type === 'user' ?
                                                <>
                                                    {userPermissions && userPermissions.includes("user_edit") &&
                                                        <Tooltip title="Edit">
                                                            <span>
                                                                <Link to={'/settings/user-detail/' + user?.id} className='icon-button'>
                                                                    <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                                </Link>
                                                            </span>
                                                        </Tooltip>
                                                    }
                                                    {userPermissions && userPermissions.includes("user_delete") &&
                                                        <Tooltip title="Delete">
                                                            <span>
                                                                <button type="button" className='icon-button' onClick={() => deleteData(user?.user_tenant_relation?.id, "user")}>
                                                                    <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                                                </button>
                                                            </span>
                                                        </Tooltip>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <Tooltip title="Resend invitation">
                                                        <span>
                                                            <button type="button" className='icon-button' onClick={() => reSendInvitation(user.id, user.email, user.role_id)}>
                                                                <Icon path={mdiSendOutline} color="currentColor" size={1} />
                                                            </button>
                                                        </span>
                                                    </Tooltip>
                                                    {userPermissions && userPermissions.includes("user_delete") &&
                                                        <Tooltip title="Delete">
                                                            <span>
                                                                <button type="button" className='icon-button' onClick={() => deleteData(user?.id, 'invited')}>
                                                                    <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                                                </button>
                                                            </span>
                                                        </Tooltip>
                                                    }
                                                </>
                                        }
                                    </Stack>
                                </li>
                            )
                        })
                    }
                </ul>
            )}
            {(!users || users.length === 0) && !loading &&
                <EmptyState type={'user'} hideActionBtn />
            }

            {
                users && users.length > 0 &&
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            }

            {
                createNewUserDialogOpen && memberShipDetail && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <CreateNewUserDialog
                            open={createNewUserDialogOpen}
                            onClose={closeCreateNewUserDialog}
                            roles={roles}
                            handleReloadFunc={handleReloadFunc}
                        />
                    </Suspense>
                )}
        </>
    );
}

export default Users;