import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Autocomplete, Button, Dialog, Grid, MenuItem, Stack, TextField, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";
import { useSelector } from "react-redux";
import MyCustomNumberFormat from "../../../components/MyCustomNumberFormat";
import PercentageFormatCustom from "../../../components/MyCustomPercentageFormat";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { displayDate } from "../../../utils/Utils";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";

function CloneLeadDialog({ open, onClose, handleReloadFunc, sources, salutation, productList, selectedProductIds, leadData, dealData, leadStage, country }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const navigate = useNavigate();

    const productOptions = productList.map(product => ({
        key: product.id,
        value: product.name
    }));

    const validationSchema = yup.object().shape({
        first_name: yup.string().trim('First name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter first name'),
        last_name: yup.string().trim('Last name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter last name'),
        title: yup.string().trim('Title cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        organization_name: yup.string().trim('Company name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter company name'),
    })


    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            salutation: values.salutation,
            first_name: values.first_name,
            last_name: values.last_name,
            title: values.title,
            primary_email: values.primary_email,
            phone1: values.phone1,
            description: values.description,
            product_id: values.product_id,
            organization_name: values.organization_name,
            organization_email: values.organization_email,
            organization_phone1: values.organization_phone1,
            organization_website: values.organization_website,
            lead_source: values.lead_source,
            department: values.department,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
            country_id: values.country_id,
            facebook_url: values.facebook_url,
            twitter_url: values.twitter_url,
            linkedin_url: values.linkedin_url,
            instagram_url: values.instagram_url,
            latitude: '',
            longitude: '',
        }
        showLoader()
        await axiosInstance.post("/api/lead/store", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
                navigate("/leads");
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }


    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>Clone Lead</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        salutation: leadData?.salutation || 'Mr',
                        first_name: leadData?.first_name || '',
                        last_name: leadData?.last_name || '',
                        title: leadData?.title || '',
                        primary_email: leadData?.primary_email || '',
                        phone1: leadData?.phone1 || '',
                        description: leadData?.description || '',
                        organization_name: leadData?.organization_name || '',
                        organization_email: leadData?.organization_email || '',
                        organization_phone1: leadData?.organization_phone1 || '',
                        organization_website: leadData?.organization_website || '',
                        department: leadData?.department || '',
                        lead_source: leadData?.lead_source || '',
                        address1: leadData?.address1 || '',
                        address2: leadData?.address2 || '',
                        city: leadData?.city || '',
                        state: leadData?.state || '',
                        zipcode: leadData?.zipcode || '',
                        country_id: leadData?.country_id || '',
                        facebook_url: leadData?.facebook_url || '',
                        twitter_url: leadData?.twitter_url || '',
                        linkedin_url: leadData?.linkedin_url || '',
                        instagram_url: leadData?.instagram_url || '',
                        product_id: selectedProductIds || [],
                        error_list: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={1.5}>
                                    <Grid item xs="auto">
                                        <TextField
                                            select
                                            className="no-label"
                                            value={values.salutation}
                                            name="salutation"
                                            variant="filled"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.salutation && touched.salutation}
                                            helperText={(errors.salutation && touched.salutation) && errors.salutation}

                                        >
                                            {Object.entries(salutation).map(([key, value], index) => (
                                                <MenuItem key={index} value={key}>
                                                    {value}
                                                </MenuItem>
                                            ))}

                                        </TextField>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            label="First Name"
                                            variant="filled"
                                            name="first_name"
                                            value={values.first_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.first_name && touched.first_name}
                                            helperText={(errors.first_name && touched.first_name) && errors.first_name}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            label="Last Name"
                                            variant="filled"
                                            name="first_name"
                                            value={values.last_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.first_name && touched.first_name}
                                            helperText={(errors.first_name && touched.first_name) && errors.first_name}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Lead Title"
                                            variant="filled"
                                            name="title"
                                            value={values?.title || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.title && touched.title}
                                            helperText={(errors.title && touched.title) && errors.title}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Email"
                                            variant="filled"
                                            name="primary_email"
                                            value={values?.primary_email || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.primary_email && touched.primary_email}
                                            helperText={(errors.primary_email && touched.primary_email) && errors.primary_email}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Phone"
                                            variant="filled"
                                            name="phone1"
                                            value={values?.phone1 || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.phone1 && touched.phone1}
                                            helperText={(errors.phone1 && touched.phone1) && errors.phone1}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Description"
                                            variant="filled"
                                            multiline
                                            rows={3}
                                            name="description"
                                            value={values?.description || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.description && touched.description}
                                            helperText={(errors.description && touched.description) && errors.description}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            id="multi_selects"
                                            options={productOptions || []}
                                            getOptionLabel={(option) => option.value}
                                            value={productOptions.filter(option => values.product_id.includes(option.key))} // Show selected products
                                            onChange={(event, newValue) => {
                                                const selectedProductIds = newValue.map(option => option.key); // Extract product IDs
                                                setFieldValue('product_id', selectedProductIds); // Update Formik state
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    label="Select Product"
                                                    placeholder="Select product"
                                                    autoFocus
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        onFocus: props.focusCallback,
                                                    }}
                                                />
                                            )}
                                            error={errors.product_id && touched.product_id}
                                            helperText={(errors.product_id && touched.product_id) && errors.product_id}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Company Name"
                                            variant="filled"
                                            name="organization_name"
                                            value={values?.organization_name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.organization_name && touched.organization_name}
                                            helperText={(errors.organization_name && touched.organization_name) && errors.organization_name}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Company Email"
                                            variant="filled"
                                            name="organization_email"
                                            value={values?.organization_email || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.organization_email && touched.organization_email}
                                            helperText={(errors.organization_email && touched.organization_email) && errors.organization_email}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Company Phone"
                                            variant="filled"
                                            name="organization_phone1"
                                            value={values?.organization_phone1 || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.organization_phone1 && touched.organization_phone1}
                                            helperText={(errors.organization_phone1 && touched.organization_phone1) && errors.organization_phone1}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Company Website"
                                            variant="filled"
                                            name="organization_website"
                                            value={values?.organization_website || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.organization_website && touched.organization_website}
                                            helperText={(errors.organization_website && touched.organization_website) && errors.organization_website}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Department"
                                            variant="filled"
                                            name="department"
                                            value={values?.department || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.department && touched.department}
                                            helperText={(errors.department && touched.department) && errors.department}
                                        />

                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            select
                                            label="Lead Source"
                                            value={values.lead_source}
                                            name="lead_source"
                                            variant="filled"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.lead_source && touched.lead_source}
                                            helperText={(errors.lead_source && touched.lead_source) && errors.lead_source}

                                        >
                                            {
                                                sources?.length > 0 ?
                                                    sources.map((source, index) => (
                                                        <MenuItem key={index} value={source.title}>
                                                            {source.title}
                                                        </MenuItem>
                                                    )) :
                                                    <MenuItem value={-1}></MenuItem>

                                            }

                                        </TextField>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Address1"
                                            variant="filled"
                                            name="deal_description"
                                            value={values?.address1 || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.address1 && touched.address1}
                                            helperText={(errors.address1 && touched.address1) && errors.address1}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Address2"
                                            variant="filled"
                                            name="address2"
                                            value={values?.address2 || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.address2 && touched.address2}
                                            helperText={(errors.address2 && touched.address2) && errors.address2}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="City"
                                            variant="filled"
                                            name="city"
                                            value={values?.city || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.city && touched.city}
                                            helperText={(errors.city && touched.city) && errors.city}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="State"
                                            variant="filled"
                                            name="state"
                                            value={values?.state || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.state && touched.state}
                                            helperText={(errors.state && touched.state) && errors.state}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Zipcode"
                                            variant="filled"
                                            name="first_name"
                                            value={values?.zipcode || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.zipcode && touched.zipcode}
                                            helperText={(errors.zipcode && touched.zipcode) && errors.zipcode}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            select
                                            label="Country"
                                            value={values.country_id}
                                            name="country_id"
                                            variant="filled"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.country_id && touched.country_id}
                                            helperText={(errors.country_id && touched.country_id) && errors.country_id}

                                        >
                                            {
                                                country?.length > 0 ?
                                                    country.map((contry, index) => (
                                                        <MenuItem key={index} value={contry.id}>
                                                            {contry.country_name}
                                                        </MenuItem>
                                                    )) :
                                                    <MenuItem value={-1}></MenuItem>

                                            }

                                        </TextField>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Facebook"
                                            variant="filled"
                                            name="facebook_url"
                                            value={values?.facebook_url || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.facebook_url && touched.facebook_url}
                                            helperText={(errors.facebook_url && touched.facebook_url) && errors.facebook_url}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Twitter"
                                            variant="filled"
                                            name="twitter_url"
                                            value={values?.twitter_url || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.twitter_url && touched.twitter_url}
                                            helperText={(errors.twitter_url && touched.twitter_url) && errors.twitter_url}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="LinkedIn"
                                            variant="filled"
                                            name="linkedin_url"
                                            value={values?.linkedin_url || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.linkedin_url && touched.linkedin_url}
                                            helperText={(errors.linkedin_url && touched.linkedin_url) && errors.linkedin_url}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Instagram"
                                            variant="filled"
                                            name="instagram_url"
                                            value={values?.instagram_url || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.instagram_url && touched.instagram_url}
                                            helperText={(errors.instagram_url && touched.instagram_url) && errors.instagram_url}
                                        />
                                    </Grid>
                                </Grid>

                                <Stack direction="row" spacing={1.5} justifyContent={'flex-end'}>
                                    <Button type="submit">Clone</Button>
                                    <Button color="secondary" onClick={onClose}>Cancel</Button>
                                </Stack>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default CloneLeadDialog;