import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Popover, Radio, RadioGroup, Stack, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiAccountEditOutline, mdiAccountOutline, mdiBriefcaseAccountOutline, mdiCalendarCheckOutline, mdiClose, mdiDotsVertical, mdiExportVariant, mdiFileDocumentPlusOutline, mdiHistory, mdiMagnify, mdiPencilOutline, mdiPlus, mdiSort, mdiTrashCanOutline, mdiViewColumnOutline } from "@mdi/js";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ChangeOwnerDialog from '../lead/components/ChangeOwnerDialog';
import CreateNewDealDialog from "./components/CreateNewDealDialog";
import CreateDealForExistingAccountDialog from "./components/CreateDealForExistingAccountDialog";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import ExportData from "../../components/ExportData";
import { capitalize, DeleteDialog, DeleteDialogConfig, displayCurrencySymbol, displayDate } from "../../utils/Utils";
import EmptyState from "../../components/common/EmptyState";
import DealKanbanView from "./components/DealKanbanView";


function Deals(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { preferredDateFormat, preTimeZoneKey, currencySymbol, currencyCode, currencyStringValue } = useSelector((state) => state?.user);
    const [listView, setListView] = useState(true);
    const [clearType, setClearType] = useState(false);
    const userPermissions = useSelector((state) => state.user.permission);

    // Extract URL parameters
    const [searchParams] = useSearchParams();
    const orderByParam = searchParams.get("sort_order") || process.env.REACT_APP_DEFAULT_SORT;
    const sortByParam = searchParams.get("sort_field") || "created_at";

    //add for handle apply button
    const [handleSortBy, setHandleSortBy] = useState(sortByParam);
    const [handleOrderBy, setHandleOrderBy] = useState(orderByParam);

    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );
    const [sortBy, setSortBy] = useState("created_at");
    const [sortByOpen, setSortByOpen] = useState(false);
    const [sortByAnchorEl, setSortByAnchorEl] = useState(null);

    // State management for search fields
    const [search, setSearch] = useState("");
    const [searchLeadSource, setSearchLeadSource] = useState('All Sources');

    //handle checkbox
    const [checkedItems, setCheckedItems] = useState({});
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [reloadPage, setReloadPage] = useState(false);

    const [leadSourceList, setLeadSourceList] = useState([]);
    const [userLists, setUserLists] = useState([]);
    const [leadSalutation, setLeadSalutation] = useState([]);
    const [deals, setDeals] = useState();


    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        async function fetchData() {
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchLeadSource,
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        searchLeadSource,
        reloadPage,
        clearType,
        listView,
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
        searchLeadSource,
    ) => {
        const params = {
            search: search,
            search_deal_source:
                searchLeadSource === "All Sources" ? "" : searchLeadSource,
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
        };
        showLoader();
        setIsLoading(true);
        await axiosInstance.get(`api/deal?page=${currentPage + 1}`, { params }).then((response) => {
            if (response.status === 200) {
                setDeals(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                setLeadSourceList(response?.data?.data?.leadSources || []);
                setUserLists(response?.data?.data?.users || [])
                setLeadSalutation(response?.data?.data?.leadSalutation || '');


                setCheckedItems({});
                setIsCheckAll(false);
                hideLoader();
                setIsLoading(false);
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    setIsLoading(false);
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/dashboard");
                    }
                }
            });
    };


    //=====Start::Write a code for handle headeMenu option ======//
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleSortByClickToOpen = (event) => {
        setSortByAnchorEl(event.currentTarget);
        setSortByOpen(true);
    };

    const handleSortByClose = () => {
        setSortByOpen(false);
        setSortByAnchorEl(null);
    };

    const handleSortByReset = () => {
        setSortByOpen(false);
        setSortByAnchorEl(null);

        setSortBy("created_at");
        setSortOrder("desc");
    };

    const handleSortByApply = () => {
        //click to manage apply button....
        setSortBy(handleSortBy);
        setSortOrder(handleOrderBy);

        setSortByOpen(false);
        setSortByAnchorEl(null);
    };

    const handleSortChange = (event) => {
        const { name, value } = event.target;
        if (name === "sort-by-radio") {
            //setSortBy(value); //if dont want to click on apply button than open it and hide setHandleSortBy
            setHandleSortBy(value);
        } else if (name === "sort-order-radio") {
            //setSortOrder(value);
            setHandleOrderBy(value);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default form submission behavior
            getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchLeadSource,
            );
        }
    };

    const HandleSearchSource = (event) => {
        setSearchLeadSource(event.target.value);
    };

    const handleCheckAll = (event) => {
        const { checked } = event.target;
        setIsCheckAll(checked);
        const newCheckedItems = {};
        deals.forEach((deal) => {
            newCheckedItems[deal.id] = checked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({
            ...checkedItems,
            [name]: checked,
        });

        if (!checked) {
            setIsCheckAll(false);
        } else {
            const allChecked = deals.every(
                (deal) => checkedItems[deal.id] || deal.name === name
            );
            setIsCheckAll(allChecked);
        }
    };


    // Get list of checked item IDs
    const getCheckedIds = () => {
        return Object.keys(checkedItems).filter((id) => checkedItems[id]);
    };

    // Handle single delete record
    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/deal/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                        navigate("/deals");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    // Handle multiple delete records
    async function handleBulkDelete() {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            bulkDeleteData(checkedIds)
        } else {
            showSnackbar('Please select at least one record to Delete.', 'warning')
        }
    }

    // Handle multiple delete records
    const bulkDeleteData = async (ids) => {
        const data = {
            id: ids,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete('api/deal/bulk-destroy', { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    setReloadPage(reloadPage ? false : true);
                                    hideLoader();
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                        navigate("/deals");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=====End::Write a code for handle headeMenu option ======//


    //======Start:Write a Export data=====//
    const checkedIds = getCheckedIds();
    const expParams = {
        search: search,
        search_deal_source: searchLeadSource === "All Sources" ? "" : searchLeadSource,
        sort_field: sortBy,
        sort_order: sortOrder,
        per_page: perPage,
        selectedIds: checkedIds && checkedIds.length > 0 ? checkedIds : []
    };
    const handleReloadFunc = () => {
        setCheckedItems({});
        setIsCheckAll(false);
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }
    //======End:Write a Export data=====//

    const displayStatusColor = {
        'Open': 'status-badge warning',
        'Closed Lost': 'status-badge error',
        'Closed Won': 'status-badge success',
    };


    const [dealPageCreateNewContextMenuAnchorEl, setDealPageCreateNewContextMenuAnchorEl] = React.useState(null);
    const dealPageCreateNewContextMenuOpen = Boolean(dealPageCreateNewContextMenuAnchorEl);
    const handleDealPageCreateNewContextMenuClick = (event) => {
        setDealPageCreateNewContextMenuAnchorEl(event.currentTarget);
    };
    const handleDealPageCreateNewContextMenuClose = () => {
        setDealPageCreateNewContextMenuAnchorEl(null);
    };

    const [dealPageContextMenuAnchorEl, setDealPageContextMenuAnchorEl] = React.useState(null);
    const dealPageContextMenuOpen = Boolean(dealPageContextMenuAnchorEl);
    const handleDealPageContextMenuClick = (event) => {
        setDealPageContextMenuAnchorEl(event.currentTarget);
    };
    const handleDealPageContextMenuClose = () => {
        setDealPageContextMenuAnchorEl(null);
    };


    //=======Start:Wtite a logic for change owner===========//
    const [changeOwnerDialogOpen, setChangeOwnerDialogOpen] =
        React.useState(false);
    const openChangeOwnerDialog = () => {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            setChangeOwnerDialogOpen(true);
        } else {
            showSnackbar('Please select at least one record to change owner.', 'warning')
        }

    };

    const closeChangeOwnerDialog = () => {
        setChangeOwnerDialogOpen(false);
    };

    const handleChangeOwner = async (ownerId) => {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0 && ownerId) {
            const formData = new FormData();
            formData.append('owner_id', ownerId)
            for (var i = 0; i < checkedIds.length; i++) {
                formData.append('deal_id[]', checkedIds[i])
            }

            await axiosInstance.post("api/deal-change-owner", formData).then(response => {
                if (response.status === 200) {
                    showSnackbar(response.data.message, "success");
                    setCheckedItems({});
                    setIsCheckAll(false);
                    hideLoader();
                    setReloadPage(reloadPage ? false : true);
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/deals");
                    }
                }
            });
        } else {
            showSnackbar('Please select at least one record to change owner.', 'warning')
        }
    }
    //=======End:Wtite a logic for change owner===========//


    //========Start:Write a code for create a new Deal==========//
    const [createNewDealDialogOpen, setCreateNewDealDialogOpen] = React.useState(false);
    const openCreateNewDealDialog = () => {
        setCreateNewDealDialogOpen(true);
    }
    const closeCreateNewDealDialog = () => {
        setCreateNewDealDialogOpen(false);
    }
    //========End:Write a code for create a new Deal==========//

    //========Start:Write a code for create a new Deal with existing account==========//
    const [createDealForExistingAccountDialogOpen, setCreateDealForExistingAccountDialogOpen] = React.useState(false);
    const openCreateDealForExistingAccountDialog = () => {
        setCreateDealForExistingAccountDialogOpen(true);
    }
    const closeCreateDealForExistingAccountDialog = () => {
        setCreateDealForExistingAccountDialogOpen(false);
    }
    //========End:Write a code for create a new Deal with existing account==========//

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        } else if (type === 'source') {
            setSearchLeadSource('All Sources')
        } else {
            setSearch('')
            setSearchLeadSource('All Sources')
        }
    }

    const handleListViewFun = () => {
        setListView(listView ? false : true)
    }

    if (listView) {
        return (
            <>
                <Helmet>
                    <title>Deals | {capitalize(props?.name || 'SolvCRM')}</title>
                </Helmet>

                <Grid container className='page-action-bar'>
                    <Grid item xxl={'auto'} xs={12} sx={{ marginLeft: { xxl: 'auto' }, order: { xxl: 1 }, marginBottom: { xxl: 0, xs: '12px' } }}>
                        <Stack direction="row" spacing={1}>
                            {userPermissions && userPermissions.includes("deal_create") &&
                                <Button size="small" onClick={handleDealPageCreateNewContextMenuClick}>
                                    <span>
                                        <Icon path={mdiPlus} color="currentColor" size={1} />
                                        <span>Create New</span>
                                    </span>
                                </Button>
                            }
                            <Menu
                                anchorEl={dealPageCreateNewContextMenuAnchorEl}
                                open={dealPageCreateNewContextMenuOpen}
                                onClose={handleDealPageCreateNewContextMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem onClick={e => {
                                    handleDealPageCreateNewContextMenuClose();
                                    openCreateNewDealDialog();
                                }}>
                                    <Icon path={mdiPlus} color={'currentColor'} size={1} />
                                    <span>Create New</span>
                                </MenuItem>
                                <MenuItem onClick={e => {
                                    handleDealPageCreateNewContextMenuClose();
                                    openCreateDealForExistingAccountDialog();
                                }}>
                                    <Icon path={mdiFileDocumentPlusOutline} color={'currentColor'} size={1} />
                                    <span>Create New for Existing Account</span>
                                </MenuItem>
                            </Menu>

                            <Button variant="contained" color="secondary" size='small' onClick={() => setListView(listView ? false : true)}>
                                <span>
                                    <Icon path={mdiViewColumnOutline} color="currentColor" size={1} />
                                    <span>Kanban View</span>
                                </span>
                            </Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={handleSortByClickToOpen}
                            >
                                <span>
                                    <Icon path={mdiSort} color="currentColor" size={1} />
                                    <span>Sort by</span>
                                </span>
                            </Button>
                            <Popover
                                open={sortByOpen}
                                anchorEl={sortByAnchorEl}
                                onClose={handleSortByClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                classes={{
                                    paper: "utility-menu",
                                }}
                            >
                                <Typography
                                    variant="label-small-semibold"
                                    className="small-title"
                                >
                                    Sort by
                                </Typography>
                                <RadioGroup
                                    //defaultValue="name"
                                    value={handleSortBy}
                                    name="sort-by-radio"
                                    onChange={handleSortChange}
                                >
                                    <FormControlLabel
                                        value="name"
                                        control={<Radio />}
                                        label="Name"
                                    />
                                    <FormControlLabel
                                        value="title"
                                        control={<Radio />}
                                        label="Title"
                                    />
                                    <FormControlLabel
                                        value="phone1"
                                        control={<Radio />}
                                        label="Phone"
                                    />
                                    <FormControlLabel
                                        value="organization_name"
                                        control={<Radio />}
                                        label="Org. Name"
                                    />
                                    <FormControlLabel
                                        value="added_by"
                                        control={<Radio />}
                                        label="Added by"
                                    />
                                    <FormControlLabel
                                        value="created_at"
                                        control={<Radio />}
                                        label="Created at"
                                    />
                                </RadioGroup>

                                <Typography
                                    variant="label-small-semibold"
                                    className="small-title"
                                >
                                    Sort Order
                                </Typography>
                                <RadioGroup
                                    //defaultValue="desc"
                                    value={handleOrderBy}
                                    name="sort-order-radio"
                                    onChange={handleSortChange}
                                >
                                    <FormControlLabel
                                        value="asc"
                                        control={<Radio />}
                                        label="Ascending"
                                    />
                                    <FormControlLabel
                                        value="desc"
                                        control={<Radio />}
                                        label="Descending"
                                    />
                                </RadioGroup>

                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Button
                                            size="small"
                                            className="btn-block"
                                            onClick={handleSortByApply}
                                        >
                                            Apply
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            className="btn-block"
                                            onClick={handleSortByReset}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Popover>
                            <Tooltip title="More">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    className="icon-only"
                                    onClick={handleDealPageContextMenuClick}
                                >
                                    <Icon path={mdiDotsVertical} color="currentColor" size={1} />
                                </Button>
                            </Tooltip>

                            <Menu
                                anchorEl={dealPageContextMenuAnchorEl}
                                open={dealPageContextMenuOpen}
                                onClose={handleDealPageContextMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem onClick={(e) => { handleDealPageContextMenuClose() }}>
                                    <Icon path={mdiExportVariant} color={"currentColor"} size={1} />
                                    <ExportData params={expParams} fileName="Deal" api="deal" handleReloadFunc={handleReloadFunc} />
                                </MenuItem>

                                {userPermissions && userPermissions.includes("deal_edit") &&
                                    <MenuItem onClick={e => {
                                        handleDealPageContextMenuClose();
                                        openChangeOwnerDialog();
                                    }}>
                                        <Icon path={mdiAccountEditOutline} color={'currentColor'} size={1} />
                                        <span>Change Owner</span>
                                    </MenuItem>
                                }

                                {userPermissions && userPermissions.includes("deal_delete") &&
                                    <MenuItem
                                        onClick={() => {
                                            handleBulkDelete(); handleDealPageContextMenuClose();
                                        }}
                                    >
                                        <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                        <span>Delete</span>
                                    </MenuItem>
                                }
                            </Menu>
                        </Stack>
                    </Grid>
                    <Grid item xxl={"auto"} xs={12}>
                        <Stack direction="row" spacing={1} alignItems={'center'}>
                            <Checkbox
                                className="grid-checkbox"
                                checked={isCheckAll}
                                onChange={handleCheckAll}
                            />
                            <TextField
                                variant="filled"
                                placeholder="Search deals"
                                name="searchData"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={handleKeyDown}
                                className="size-small page-search no-margin"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon path={mdiMagnify} color="currentColor" size={1} />
                                            {search &&
                                                <IconButton onClick={() => clearUserFilter('search')} >
                                                    <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                                </IconButton>
                                            }

                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                select
                                //defaultValue="All Sources"
                                variant="filled"
                                value={searchLeadSource}
                                className="size-small no-label no-margin"
                                onChange={HandleSearchSource}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end' className='clear-filter'>
                                            {searchLeadSource && searchLeadSource !== 'All Sources' &&
                                                <IconButton onClick={() => clearUserFilter('source')} >
                                                    <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                                </IconButton>
                                            }
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                <MenuItem key={1222} value="All Sources">
                                    All Sources
                                </MenuItem>
                                {leadSourceList &&
                                    leadSourceList.length > 0 &&
                                    leadSourceList.map((source, index) => (
                                        <MenuItem key={index} value={source.title}>
                                            {source.title}
                                        </MenuItem>
                                    ))}
                            </TextField>
                            {
                                (search || (searchLeadSource && searchLeadSource !== 'All Sources')) &&
                                <a href="-" className="primary-link ml-12" onClick={e => { e.preventDefault(); clearUserFilter('all') }}><Typography variant='body-medium-medium'>Clear All</Typography></a>
                            }


                        </Stack>
                    </Grid>
                </Grid>


                {deals && deals.length > 0 && (
                    <ul className="primary-list">
                        {deals.map((deal, index) => {
                            const Salutation = deal?.get_contact?.salutation ? deal.get_contact.salutation + '.' : ''
                            const Fname = deal?.get_contact?.first_name
                            const Lname = deal?.get_contact?.last_name
                            const constPersonName = Salutation + ' ' + Fname + ' ' + Lname
                            return (
                                <li key={index + ''}>
                                    <Link to={userPermissions?.includes("deal_edit") ? '/deal-detail/' + deal?.id : '#'} className="card">
                                        <Grid container alignItems={'flex-start'}>
                                            <Grid item xs={"auto"}>
                                                <Checkbox
                                                    className="grid-checkbox"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                    name={String(deal.id)}
                                                    checked={checkedItems[deal.id] || false}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='title-medium-bold' component={'h6'}>{capitalize(deal?.deal_name || 'N/A')}</Typography>
                                                <ul className='inline-details'>
                                                    <li><span className="bold">{deal?.deal_amount ? displayCurrencySymbol(deal?.deal_amount, currencyCode, currencySymbol, currencyStringValue) : 'N/A'}</span></li>
                                                    <li>
                                                        <Tooltip title="Stage">
                                                            <span>
                                                                <span className={`status-badge ${deal?.selected_stage_name?.name ? displayStatusColor[deal.selected_stage_name.name] : ''}`}>{deal?.selected_stage_name?.name}</span>
                                                            </span>
                                                        </Tooltip>
                                                    </li>
                                                    <li>
                                                        <Tooltip title="Expected Closing Date">
                                                            <span>
                                                                <Icon path={mdiCalendarCheckOutline} color="currentColor" size={1} />{deal?.expected_closing_date
                                                                    ? displayDate(moment.utc(deal.expected_closing_date).tz(preTimeZoneKey).format(preferredDateFormat), 'half')
                                                                    : "N/A"}
                                                            </span>
                                                        </Tooltip>
                                                    </li>
                                                    <li>
                                                        <Tooltip title="Contact Person">
                                                            <span>
                                                                <Icon path={mdiBriefcaseAccountOutline} color="currentColor" size={1} />{constPersonName ? capitalize(constPersonName) : 'N/A'}
                                                            </span>
                                                        </Tooltip>
                                                    </li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                        <ul className='inline-details other-info no-avatar'>
                                            <li><span className='title'>Product Interested:</span> {deal?.selected_product || 'N/A'}</li>
                                            <li>
                                                <Tooltip title="Added by">
                                                    <span>
                                                        <Icon path={mdiAccountOutline} color="currentColor" size={1} /> {capitalize(deal?.added_by?.name || 'N/A')}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                            <li>
                                                <Tooltip title="Last Updated">
                                                    <span>
                                                        <Icon path={mdiHistory} color="currentColor" size={1} /> {deal?.updated_at
                                                            ? moment.utc(deal.updated_at)
                                                                .tz(preTimeZoneKey)
                                                                .format(preferredDateFormat)
                                                            : "N/A"}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </Link>
                                    <Stack direction={'row'} className='actions'>

                                        {userPermissions && userPermissions.includes("deal_edit") &&
                                            <Tooltip title="Edit">
                                                <span>
                                                    <Link to={'/deal-detail/' + deal?.id} className='icon-button'>
                                                        <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                    </Link>
                                                </span>
                                            </Tooltip>
                                        }
                                        {userPermissions && userPermissions.includes("deal_delete") &&
                                            <Tooltip title="Delete">
                                                <button type="button" className='icon-button' onClick={() => { deleteData(deal?.id) }} >
                                                    <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                                </button>
                                            </Tooltip>
                                        }
                                    </Stack>
                                </li>
                            )
                        })}
                    </ul>
                )}

                {(deals?.length == 0) && !isLoading &&
                    <EmptyState type={'deal'} hideActionBtn />
                }


                {
                    deals && deals.length > 0 &&
                    <TablePagination
                        component="div"
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={perPage}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                }


                {
                    changeOwnerDialogOpen && deals && deals.length > 0 && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <ChangeOwnerDialog
                                open={changeOwnerDialogOpen}
                                onClose={closeChangeOwnerDialog}
                                users={userLists}
                                handleChangeOwner={handleChangeOwner}
                                type='Deal'
                            /> </Suspense>
                    )
                }
                {
                    createNewDealDialogOpen && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <CreateNewDealDialog
                                open={createNewDealDialogOpen}
                                onClose={closeCreateNewDealDialog}
                                salutation={leadSalutation}
                                sources={leadSourceList}
                                handleReloadFunc={handleReloadFunc}
                            />
                        </Suspense>
                    )}

                {
                    createDealForExistingAccountDialogOpen && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <CreateDealForExistingAccountDialog
                                open={createDealForExistingAccountDialogOpen}
                                onClose={closeCreateDealForExistingAccountDialog}
                                sources={leadSourceList}
                                handleReloadFunc={handleReloadFunc}
                            />
                        </Suspense>
                    )}


            </>
        );
    } else {
        return (
            <>
                <Helmet>
                    <title>Deals | {capitalize(props?.name || 'SolvCRM')}</title>
                </Helmet>

                {
                    !listView && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <DealKanbanView handleListViewFun={handleListViewFun} userPermissions={userPermissions} />
                        </Suspense>
                    )
                }

            </>
        );
    }
}

export default Deals;